import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Box,
  Avatar
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// utils
import { capitalCase } from 'text-case';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Label from '../components/Label';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { AddDirectorModal, EditDirectorModal, ShowMediaModal } from '../components/directors';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'about', label: 'About', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        `${_user.firstname} ${_user.lastname}`.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Directors() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [directorsData, setDirectorsData] = useState([]);
  const [addDirectorModalOpen, setAddDirectorModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [showMediaModalOpen, setShowMediaModalOpen] = useState(false);
  const [selectedDirector, setSelectedDirector] = useState({});
  const [showEditModalOpen, setShowEditModalOpen] = useState(false);

  useEffect(() => {
    fetchDirectors();
  }, []);

  const fetchDirectors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/director`);

      setDirectorsData(res.data.data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(directorsData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleShowMediaModal = (media) => {
    setSelectedMedia(media);
    setShowMediaModalOpen(true);
  };

  const handleShowEditModal = (director) => {
    setSelectedDirector(director);
    setShowEditModalOpen(true);
  };

  const handleDelete = async (director) => {
    try {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/director/${director._id}`, {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': storedData.token
        },
        withCredentials: true
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
    fetchDirectors();
  };

  const handlePublish = async (director) => {
    try {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/director/${director._id}`, director, {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': storedData.token
        },
        withCredentials: true
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
    fetchDirectors();
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const items = reorder(directorsData, result.source.index, result.destination.index);

    const body = items.map((item, i) => ({
      id: item._id,
      name: item.firstname,
      newOrder: i
    }));

    setDirectorsData(items);

    try {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/director/order/sort`, body, {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': storedData.token
        },
        withCredentials: true
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <Page title="Fragment CMS | Directors">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Directors
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setAddDirectorModalOpen(true)}
          >
            Add Director
          </Button>
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" pr={2}>
            <Box>
              <UserListToolbar
                filterName={filterName}
                onFilterName={handleFilterByName}
                searchLabel="Search directors..."
              />
            </Box>
          </Stack>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                      {filteredUsers.map((row, index) => (
                        <Draggable key={row._id} draggableId={row._id} index={index}>
                          {(provided) => {
                            const { _id, firstname, lastname, avatar, about, isActive, status } =
                              row;
                            return (
                              <TableRow
                                hover
                                key={_id}
                                tabIndex={-1}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableCell component="th" scope="row">
                                  <Stack direction="row" alignItems="center" spacing={1}>
                                    <Avatar
                                      alt={capitalCase(`${firstname} ${lastname}`)}
                                      src={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/directors/avatar/${avatar}`}
                                      sx={{ cursor: 'pointer' }}
                                      onClick={() =>
                                        handleShowMediaModal(
                                          `https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/directors/avatar/${avatar}`
                                        )
                                      }
                                    />
                                    <Typography variant="subtitle2" noWrap>
                                      {capitalCase(`${firstname} ${lastname}`)}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography noWrap sx={{ maxWidth: 600 }}>
                                    {about}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                                    {isActive ? 'Yes' : 'No'}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">
                                  <Label
                                    variant="ghost"
                                    color={status !== 'draft' ? 'success' : 'default'}
                                  >
                                    {capitalCase(status)}
                                  </Label>
                                </TableCell>
                                <TableCell align="right">
                                  <UserMoreMenu
                                    actions={[
                                      {
                                        id: 'edit',
                                        label: 'Edit',
                                        icon: 'eva:edit-2-outline',
                                        onClick: () => handleShowEditModal(row)
                                      },
                                      {
                                        id: 'delete',
                                        label: 'Delete',
                                        icon: 'eva:trash-outline',
                                        onClick: () => handleDelete(row)
                                      },
                                      row.status === 'draft' && {
                                        id: 'publish',
                                        label: 'Publish',
                                        icon: 'eva:cloud-upload-outline',
                                        onClick: () => handlePublish(row)
                                      }
                                    ]}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </Container>
      <AddDirectorModal
        open={addDirectorModalOpen}
        setOpen={setAddDirectorModalOpen}
        fetchDirectors={fetchDirectors}
      />
      <ShowMediaModal
        open={showMediaModalOpen}
        setOpen={setShowMediaModalOpen}
        selectedMedia={selectedMedia}
      />
      <EditDirectorModal
        open={showEditModalOpen}
        setOpen={setShowEditModalOpen}
        selectedDirector={selectedDirector}
        fetchDirectors={fetchDirectors}
      />
    </Page>
  );
}
