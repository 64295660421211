import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const MAIN_REEL_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
];

// ----------------------------------------------------------------------

AddReelForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  fetchReels: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function AddReelForm({ setOpen, fetchReels }) {
  const [fileName, setFileName] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState('');
  const [isValidThumbnail, setIsValidThumbnail] = useState(true);
  const [thumbnailError, setThumbnailError] = useState('');
  const [error, setError] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleUploadFile = () => {
    hiddenFileInput.current.click();
  };

  const ReelSchema = Yup.object().shape({
    title: Yup.string().required('Title name is required'),
    subtitle: Yup.string().required('Subtitle is required'),
    vimeoUrl: Yup.string().required('Vimeo URL is required'),
    mainReel: Yup.bool().required('Main Reel is required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      vimeoUrl: '',
      mainReel: ''
    },
    validationSchema: ReelSchema,
    onSubmit: async (data) => {
      let newIsValidThumbnail = isValidThumbnail;
      let newThumbnailError = thumbnailError;

      if (!thumbnailFile) {
        newIsValidThumbnail = false;
        newThumbnailError = 'Thumbnail is required';
      } else if (thumbnailFile.size > 2 * 1024 * 1024) {
        newIsValidThumbnail = false;
        newThumbnailError = 'File is too big, please upload not more than 2mb';
      } else {
        newIsValidThumbnail = true;
      }

      setIsValidThumbnail(newIsValidThumbnail);
      setThumbnailError(newThumbnailError);

      if (thumbnailFile && newIsValidThumbnail) {
        try {
          const formData = new FormData();
          const details = JSON.stringify(data);

          formData.append('thumbnail', thumbnailFile);
          formData.append('details', details);

          const storedData = JSON.parse(localStorage.getItem('userData'));
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/reel`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'auth-token': storedData.token
            },
            withCredentials: true
          });

          if (res) {
            fetchReels();
            setOpen(false);
          } else {
            setError(true);
          }
        } catch (err) {
          setError(true);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const fileUpload = (evt) => {
    if (evt.target.files && evt.target.files[0]) {
      const xFile = evt.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const filesize = e.total;
        const limitsize = 2 * 1024 * 1024; // 8MB

        if (filesize > limitsize || !filesize) {
          setIsValidThumbnail(false);
          setThumbnailError('File is too big, please upload not more than 2mb');
        } else {
          setIsValidThumbnail(true);
        }

        setThumbnailFile(xFile);
        setFileName(xFile.name);
      };

      reader.readAsDataURL(xFile);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="title"
                label="Title"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                {...getFieldProps('title')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="subtitle"
                label="Subtitle"
                error={Boolean(touched.subtitle && errors.subtitle)}
                helperText={touched.subtitle && errors.subtitle}
                {...getFieldProps('subtitle')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="thumbnail"
                onClick={handleUploadFile}
                label="Thumbnail"
                value={fileName}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={hiddenFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e)}
              />
              {!isValidThumbnail.thumbnail && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {thumbnailError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Main Reel"
                autoComplete="mainReel"
                error={Boolean(touched.mainReel && errors.mainReel)}
                helperText={touched.mainReel && errors.mainReel}
                {...getFieldProps('mainReel')}
              >
                {MAIN_REEL_OPTIONS.map((mainReel) => (
                  <MenuItem key={mainReel.value} value={mainReel.value}>
                    {mainReel.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="vimeoUrl"
                label="Vimeo URL"
                error={Boolean(touched.vimeoUrl && errors.vimeoUrl)}
                helperText={touched.vimeoUrl && errors.vimeoUrl}
                {...getFieldProps('vimeoUrl')}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save Project
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
