import { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';

import DOMPurify from 'dompurify';

import UpdateAboutPageModal from '../components/aboutpage/UpdateAboutPageModal';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import aboutPageAPI from '../api/about-page';

export default function AboutPage() {
  const [updateAboutPageModalOpen, setUpdateAboutPageModalOpen] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [aboutPage, setAboutPage] = useState(null);
  const [isDraft, setIsDraft] = useState(false);
  const [draft, setDraft] = useState(null);

  const fetchAboutPage = async (action, id) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'discard':
        await aboutPageAPI.discardDraft({ id });
        break;

      case 'publish':
        await aboutPageAPI.publishAbout({ id });
        break;
    }

    setIsPublishing(false);
    setIsDiscarding(false);

    const { data } = await aboutPageAPI.get();
    const { draft, published, isPreview } = data;

    setDraft(draft);
    setIsDraft(isPreview);
    setAboutPage(isPreview ? draft : published);
  };

  useEffect(() => {
    fetchAboutPage();
  }, []);

  const handlePublish = (id) => {
    setIsPublishing(true);
    fetchAboutPage('publish', id);
  };

  const handleDiscard = (id) => {
    setIsDiscarding(true);
    fetchAboutPage('discard', id);
  };

  return (
    <Page title="Fragment CMS | About Page">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            About Page
          </Typography>
          <Stack direction="row" spacing={1}>
            {isDraft && (
              <>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  loading={isPublishing}
                  startIcon={<Iconify icon="eva:cloud-upload-outline" />}
                  onClick={() => handlePublish(draft._id)}
                >
                  Publish
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={isDiscarding}
                  startIcon={<Iconify icon="eva:trash-outline" />}
                  onClick={() => handleDiscard(draft._id)}
                >
                  Discard
                </LoadingButton>
              </>
            )}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:edit-outline" />}
              onClick={() => setUpdateAboutPageModalOpen(true)}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
        <Card>
          <CardContent>
            <Stack spacing={6}>
              <Stack spacing={2}>
                <Typography variant="subtitle2">Slide Images</Typography>
                <Box p={2} bgcolor={grey[100]}>
                  {aboutPage?.slideImages.length === 0 ? (
                    <Typography>No images uploaded</Typography>
                  ) : (
                    <Grid container gap={2}>
                      {aboutPage?.slideImages.map((slideImage) => (
                        <Grid key={slideImage} item>
                          <Box
                            component="img"
                            alt="About page slide image"
                            height={70}
                            width={140}
                            bgcolor={grey[200]}
                            borderRadius="8px"
                            sx={{ objectFit: 'contain' }}
                            src={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/about-page/slide-images/${slideImage}`}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle2">Text</Typography>
                <Box p={2} bgcolor={grey[100]}>
                  <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(aboutPage?.text) }} />
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle2">Vimeo URL</Typography>
                <Box p={2} bgcolor={grey[100]}>
                  <Typography variant="body">{aboutPage?.vimeoURL}</Typography>
                </Box>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Container>
      <UpdateAboutPageModal
        open={updateAboutPageModalOpen}
        setOpen={setUpdateAboutPageModalOpen}
        aboutPage={aboutPage}
        fetchAboutPage={fetchAboutPage}
      />
    </Page>
  );
}
