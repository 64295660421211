import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
// pages
import Login from './pages/Login';
import Register from './pages/Register';
import NotFound from './pages/Page404';
import Projects from './pages/Projects';
import Directors from './pages/Directors';
import Users from './pages/User';
// context
import { AuthContext } from './shared/context/AuthContext';
import Reels from './pages/Reels';
import Categories from './pages/Categories';
import Archives from './pages/Archives';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

// ----------------------------------------------------------------------

export default function Router() {
  const authContext = useContext(AuthContext);

  return useRoutes([
    {
      path: '/cms',
      element: authContext.isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'home-page', element: <HomePage /> },
        { path: 'about-page', element: <AboutPage /> },
        { path: 'contact-page', element: <ContactPage /> },
        { path: 'projects', element: <Projects /> },
        { path: 'directors', element: <Directors /> },
        { path: 'reels', element: <Reels /> },
        { path: 'categories', element: <Categories /> },
        { path: 'archives', element: <Archives /> },
        { path: 'users', element: <Users /> }
      ]
    },
    {
      path: '/',
      element: authContext.isLoggedIn ? <Navigate to="/cms/home-page" /> : <Login />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '/404', element: <Navigate to="/404" replace /> }
  ]);
}
