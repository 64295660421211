import { memo } from 'react';

import { Box } from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../styles/rich-text-editor.css';

import PropTypes from 'prop-types';

RichTextEditor.modules = {
  toolbar: [['bold', 'italic', 'underline']],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
};

RichTextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

function RichTextEditor({ value, onChange }) {
  return (
    <Box>
      <ReactQuill value={value} onChange={onChange} modules={RichTextEditor.modules} />
    </Box>
  );
}

export default memo(RichTextEditor);
