import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, Modal, CardMedia } from '@mui/material';

// ----------------------------------------------------------------------

const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Backdrop = styled('div')({
  zIndex: '-1px',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

const ModalCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '70%',
  background: 'transparent'
}));

// ----------------------------------------------------------------------

ShowThumbnailModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedThumbnail: PropTypes.string.isRequired
};

// ----------------------------------------------------------------------

export default function ShowThumbnailModal({ open, setOpen, selectedThumbnail }) {
  return (
    <StyledModal open={open} onClose={() => setOpen(false)} BackdropComponent={Backdrop}>
      <ModalCard>
        <CardMedia
          component="img"
          alt="thumbnail"
          image={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/works/thumbnail/${selectedThumbnail}`}
        />
      </ModalCard>
    </StyledModal>
  );
}
