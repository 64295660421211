import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const ISACTIVE_OPTIONS = [
  {
    value: true,
    label: 'Active'
  },
  {
    value: false,
    label: 'Inactive'
  }
];

// ----------------------------------------------------------------------

EditCategoryForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  fetchCategories: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function EditCategoryForm({ setOpen, selectedCategory, fetchCategories }) {
  const [error, setError] = useState(false);

  const CategorySchema = Yup.object().shape({
    name: Yup.string().required('Title is required'),
    isActive: Yup.string().required('Status is required')
  });

  const formik = useFormik({
    initialValues: {
      name: selectedCategory.name,
      isActive: selectedCategory.isActive
    },
    validationSchema: CategorySchema,
    onSubmit: async (data) => {
      data.id = selectedCategory._id;

      try {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/category`, data, {
          headers: {
            'Content-Type': 'application/json',
            'auth-token': storedData.token
          },
          withCredentials: true
        });

        if (res) {
          fetchCategories();
          setOpen(false);
        } else {
          setError(true);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="name"
                label="Title"
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                {...getFieldProps('name')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Status"
                autoComplete="isActive"
                error={Boolean(touched.isActive && errors.isActive)}
                helperText={touched.isActive && errors.isActive}
                {...getFieldProps('isActive')}
              >
                {ISACTIVE_OPTIONS.map((isActive) => (
                  <MenuItem key={isActive.value} value={isActive.value}>
                    {isActive.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save Category
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
