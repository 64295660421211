import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  actions: PropTypes.array
};

// ----------------------------------------------------------------------

export default function UserMoreMenu({ actions }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {actions.map((action, index) => {
          if (action)
            return (
              <MenuItem
                id={action.key && action.key}
                key={index}
                sx={{ color: 'text.secondary' }}
                onClick={(evt) => {
                  action.onClick(evt);
                  setIsOpen(false);
                }}
              >
                <ListItemIcon>
                  <Iconify icon={action.icon} width={20} height={20} />
                </ListItemIcon>
                <ListItemText
                  primary={action.label}
                  primaryTypographyProps={{ variant: 'body2' }}
                  sx={{ textTransform: 'capitalize' }}
                />
              </MenuItem>
            );

          return null;
        })}

        {/* <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}
      </Menu>
    </>
  );
}
