import { useState, useRef } from 'react';

import { Alert, Box, TextField, Typography, FormHelperText } from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import RichTextEditor from '../RichTextEditor';
import contactPageAPI from '../../api/contact-page';

const ImagePreview = styled(Box)({
  marginTop: 4,
  height: '200px',
  width: '100%',
  objectFit: 'contain'
});

UpdateContactPageForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  contactPage: PropTypes.object,
  fetchContactPage: PropTypes.func
};

export default function UpdateContactPageForm({ setOpen, contactPage, fetchContactPage }) {
  const [textValue, setTextValue] = useState(contactPage?.text || '');
  const [isValidationError, setIsValidationError] = useState(false);
  const [previewImageUrl, setPreviewImageUrl] = useState(
    contactPage?.banner
      ? `https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/contact-page/banner/${contactPage?.banner}`
      : null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bannerFile, setBannerFile] = useState(null);
  const [bannerName, setBannerName] = useState(contactPage?.banner || '');
  const [isError, setIsError] = useState(false);
  const fileInputEl = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check file size
    const fileLimit = 30000000; // 10mb
    if (file.size > fileLimit) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl);
      setBannerFile(file);
      setBannerName(file.name);
    }
  };

  const handleTextChange = (txt) => {
    setTextValue(txt);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setIsError(false);

    const { banner } = e.target;

    const formData = new FormData();

    formData.append('text', textValue);
    formData.append('banner', banner.files[0]);

    try {
      await contactPageAPI.update(formData);
      setIsSubmitting(false);
      fetchContactPage();
      setOpen(false);
    } catch (error) {
      setIsSubmitting(false);
      setIsError(true);
    }
  };

  return (
    <Box mt={2}>
      {isError && (
        <Alert severity="error" sx={{ my: 2 }}>
          Something went wrong. Try again later.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Typography variant="caption">Text</Typography>
        <RichTextEditor value={textValue} onChange={handleTextChange} />
        <Box mb={3} />
        <TextField
          fullWidth
          variant="outlined"
          onClick={() => fileInputEl.current.click()}
          label="Banner"
          InputProps={{
            readOnly: true
          }}
          onChange={() => setBannerName(bannerFile.name)}
          value={bannerName}
        />
        <input
          ref={fileInputEl}
          type="file"
          name="banner"
          accept="image/*"
          onChange={handleFileChange}
          hidden
        />
        {isValidationError && (
          <FormHelperText error={isValidationError}>
            File is too big, please upload not more than 30mb
          </FormHelperText>
        )}
        {previewImageUrl && <ImagePreview component="img" src={previewImageUrl} />}
        <Box display="flex" justifyContent="end" mt={3}>
          <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
