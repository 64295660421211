import { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';

import DOMPurify from 'dompurify';

import UpdateContactPageModal from '../components/contactpage/UpdateContactPageModal';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

import contactPageAPI from '../api/contact-page';

export default function ContactPage() {
  const [updateContactPageModalOpen, setUpdateContactPageModalOpen] = useState(false);
  const [contactPage, setContactPage] = useState(null);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [draft, setDraft] = useState(null);

  const fetchContactPage = async (action, id) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'discard':
        await contactPageAPI.discardDraft({ id });
        break;

      case 'publish':
        await contactPageAPI.publishContact({ id });
        break;
    }

    setIsPublishing(false);
    setIsDiscarding(false);

    const { data } = await contactPageAPI.get();
    const { draft, published, isPreview } = data;

    setDraft(draft);
    setIsDraft(isPreview);
    setContactPage(isPreview ? draft : published);
  };

  useEffect(() => {
    fetchContactPage();
  }, []);

  const handlePublish = (id) => {
    setIsPublishing(true);
    fetchContactPage('publish', id);
  };

  const handleDiscard = (id) => {
    setIsDiscarding(true);
    fetchContactPage('discard', id);
  };

  return (
    <Page title="Fragment CMS | About Page">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Contact Page
          </Typography>
          <Stack direction="row" spacing={1}>
            {isDraft && (
              <>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  loading={isPublishing}
                  startIcon={<Iconify icon="eva:cloud-upload-outline" />}
                  onClick={() => handlePublish(draft._id)}
                >
                  Publish
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={isDiscarding}
                  startIcon={<Iconify icon="eva:trash-outline" />}
                  onClick={() => handleDiscard(draft._id)}
                >
                  Discard
                </LoadingButton>
              </>
            )}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:edit-outline" />}
              onClick={() => setUpdateContactPageModalOpen(true)}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
        <Card>
          <CardContent>
            <Stack spacing={6}>
              <Stack spacing={2}>
                <Typography variant="subtitle2">Text</Typography>
                <Box p={2} bgcolor={grey[100]}>
                  <Box
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contactPage?.text) }}
                  />
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Typography variant="subtitle2" mb={2} gutterBottom>
                  Banner
                </Typography>

                {contactPage?.banner && (
                  <Box
                    component="img"
                    height={200}
                    width={400}
                    borderRadius="4px"
                    bgcolor={grey[200]}
                    sx={{ objectFit: 'contain' }}
                    src={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/contact-page/banner/${contactPage?.banner}`}
                  />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Container>
      <UpdateContactPageModal
        open={updateContactPageModalOpen}
        setOpen={setUpdateContactPageModalOpen}
        contactPage={contactPage}
        fetchContactPage={fetchContactPage}
      />
    </Page>
  );
}
