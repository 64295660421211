import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, Modal, Typography } from '@mui/material';
// components
import AddArchiveForm from '../forms/AddArchiveForm';

// ----------------------------------------------------------------------

const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Backdrop = styled('div')({
  zIndex: '-1px',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

const ModalCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 500
}));

// ----------------------------------------------------------------------

AddArchiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetchArchives: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function AddArchiveModal({ open, setOpen, fetchArchives }) {
  return (
    <StyledModal open={open} onClose={() => setOpen(false)} BackdropComponent={Backdrop}>
      <ModalCard>
        <Typography variant="h6">Add Archive</Typography>
        <Typography variant="body2">Fill the details below.</Typography>
        <AddArchiveForm setOpen={setOpen} fetchArchives={fetchArchives} />
      </ModalCard>
    </StyledModal>
  );
}
