import { useContext } from 'react';
import PropTypes from 'prop-types';
// material
import { Box, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// components
import LogoutIcon from '@mui/icons-material/Logout';
import Iconify from '../../components/Iconify';
// context
import { AuthContext } from '../../shared/context/AuthContext';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const auth = useContext(AuthContext);

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <Button
            variant="contained"
            color="secondary"
            href={`${process.env.REACT_APP_SITE_URL}/api/preview?secret=${process.env.REACT_APP_PREVIEW_SECRET_TOKEN}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Preview
          </Button>
          <IconButton onClick={auth.logout}>
            <LogoutIcon sx={{ color: '#262324' }} />
          </IconButton>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
