import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Card, Modal } from '@mui/material';
// utils
import Vimeo from '@u-wave/react-vimeo';
import getVideoId from 'get-video-id';

// ----------------------------------------------------------------------

const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Backdrop = styled('div')({
  zIndex: '-1px',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

const ModalCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '80%',
  background: 'transparent',
  border: 'none'
}));

// ----------------------------------------------------------------------

ShowVimeoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  selectedVimeo: PropTypes.string.isRequired
};

// ----------------------------------------------------------------------

export default function ShowVimeoModal({ open, setOpen, selectedVimeo }) {
  const { id } = getVideoId(selectedVimeo);

  return (
    <StyledModal open={open} onClose={() => setOpen(false)} BackdropComponent={Backdrop}>
      <ModalCard>
        <Vimeo video={id} responsive autoplay />
      </ModalCard>
    </StyledModal>
  );
}
