import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Box,
  Grid,
  TextField,
  Stack,
  Typography,
  MenuItem,
  Checkbox,
  Select,
  InputLabel,
  OutlinedInput,
  ListItemText,
  FormControl
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import { capitalCase } from 'text-case';

// ----------------------------------------------------------------------

const ISACTIVE_OPTIONS = [
  {
    value: true,
    label: 'Active'
  },
  {
    value: false,
    label: 'Inactive'
  }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

// ----------------------------------------------------------------------

EditProjectForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
  fetchProjects: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function EditProjectForm({ setOpen, selectedProject, fetchProjects }) {
  const [categoriesData, setCategoriesData] = useState([]);
  const [directorsData, setDirectorsData] = useState([]);
  const [fileName, setFileName] = useState(selectedProject.thumbnail);
  const [thumbnailFile, setThumbnailFile] = useState('');
  const [categoriesValue, setCategoriesValue] = useState(() => {
    const defaultValue = [];
    selectedProject.categories.forEach((category) => {
      defaultValue.push(category.name);
    });

    return defaultValue;
  });
  const [directorsValue, setDirectorsValue] = useState(() => {
    const defaultValue = [];
    selectedProject.directors.forEach((director) => {
      defaultValue.push(capitalCase(`${director.firstname} ${director.lastname}`));
    });

    return defaultValue;
  });
  const [isValidInputs, setIsValidInputs] = useState({
    thumbnail: true,
    categories: true,
    directors: true
  });
  const [errorInputs, setErrorInputs] = useState({
    thumbnailError: '',
    categoriesError: '',
    directorsError: ''
  });
  const [error, setError] = useState(false);

  const hiddenFileInput = useRef(null);

  const handleUploadFile = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/category`);

        setCategoriesData(res.data.data);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    const fetchDirectors = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/director`);

        setDirectorsData(res.data.data);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    fetchCategories();
    fetchDirectors();
  }, []);

  const ProjectSchema = Yup.object().shape({
    projectName: Yup.string().required('Product name is required'),
    client: Yup.string().required('Client is required'),
    vimeoUrl: Yup.string().required('Vimeo URL is required')
  });

  const formik = useFormik({
    initialValues: {
      projectName: selectedProject.projectName,
      client: selectedProject.client,
      agency: selectedProject.agency,
      vimeoUrl: selectedProject.vimeoUrl,
      isActive: selectedProject.isActive
    },
    validationSchema: ProjectSchema,
    onSubmit: async (data) => {
      const newIsValidInputs = isValidInputs;
      const newErrorInputs = errorInputs;

      if (thumbnailFile) {
        if (!thumbnailFile) {
          newIsValidInputs.thumbnail = false;
          newErrorInputs.thumbnailError = 'Thumbnail is required';
        } else if (thumbnailFile.size > 2 * 1024 * 1024) {
          newIsValidInputs.thumbnail = false;
          newErrorInputs.thumbnailError = 'File is too big, please upload not more than 2mb';
        } else {
          newIsValidInputs.thumbnail = true;
        }
      }

      if (categoriesValue.length === 0) {
        newIsValidInputs.categories = false;
        newErrorInputs.categoriesError = 'Categories is required';
      } else {
        newIsValidInputs.categories = true;
      }

      if (directorsValue.length === 0) {
        newIsValidInputs.directors = false;
        newErrorInputs.directorsError = 'Directors is required';
      } else {
        newIsValidInputs.directors = true;
      }

      setIsValidInputs(newIsValidInputs);
      setErrorInputs(newErrorInputs);

      if (
        newIsValidInputs.thumbnail &&
        newErrorInputs.length !== 0 &&
        newIsValidInputs.categories &&
        newErrorInputs.length !== 0 &&
        newIsValidInputs.directors
      ) {
        data.id = selectedProject._id;
        data.thumbnail = thumbnailFile;
        data.categories = setValuesProperFormat(categoriesData, categoriesValue, 'categories');
        data.directors = setValuesProperFormat(directorsData, directorsValue, 'directors');

        try {
          const formData = new FormData();
          const details = JSON.stringify(data);

          formData.append('thumbnail', thumbnailFile);
          formData.append('details', details);

          const storedData = JSON.parse(localStorage.getItem('userData'));
          const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/work`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'auth-token': storedData.token
            },
            withCredentials: true
          });

          if (res) {
            fetchProjects();
            setOpen(false);
          } else {
            setError(true);
          }
        } catch (err) {
          setError(true);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const fileUpload = (evt) => {
    if (evt.target.files && evt.target.files[0]) {
      const xFile = evt.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const filesize = e.total;
        const limitsize = 2 * 1024 * 1024; // 8MB

        if (filesize > limitsize || !filesize) {
          setIsValidInputs((prev) => ({
            ...prev,
            thumbnail: false
          }));
          setErrorInputs((prev) => ({
            ...prev,
            thumbnailError: 'File is too big, please upload not more than 2mb'
          }));
        } else {
          setIsValidInputs((prev) => ({
            ...prev,
            thumbnail: true
          }));
        }

        setThumbnailFile(xFile);
        setFileName(xFile.name);
      };

      reader.readAsDataURL(xFile);
    }
  };

  const handleChangeSelect = (e, setFunction) => {
    const {
      target: { value }
    } = e;
    setFunction(typeof value === 'string' ? value.split(',') : value);
  };

  const setValuesProperFormat = (arrayData, arrayValue, type) => {
    const newValue = [];

    arrayValue.forEach((value) => {
      if (type === 'categories') {
        const data = arrayData.find((d) => d.name === value);

        newValue.push(data._id);
      } else if (type === 'directors') {
        const data = arrayData.find((d) => capitalCase(`${d.firstname} ${d.lastname}`) === value);

        newValue.push(data._id);
      }
    });

    return newValue;
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="projectName"
                label="Project Name"
                error={Boolean(touched.projectName && errors.projectName)}
                helperText={touched.projectName && errors.projectName}
                {...getFieldProps('projectName')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="client"
                label="Client"
                error={Boolean(touched.client && errors.client)}
                helperText={touched.client && errors.client}
                {...getFieldProps('client')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="agency"
                label="Agency"
                error={Boolean(touched.agency && errors.agency)}
                helperText={touched.agency && errors.agency}
                {...getFieldProps('agency')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="thumbnail"
                onClick={handleUploadFile}
                label="Thumbnail"
                value={fileName}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={hiddenFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e)}
              />
              {!isValidInputs.thumbnail && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.thumbnailError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="vimeoUrl"
                label="Vimeo URL"
                error={Boolean(touched.vimeoUrl && errors.vimeoUrl)}
                helperText={touched.vimeoUrl && errors.vimeoUrl}
                {...getFieldProps('vimeoUrl')}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Categories</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={categoriesValue}
                  onChange={(e) => handleChangeSelect(e, setCategoriesValue)}
                  input={<OutlinedInput label="Categories" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {categoriesData.map((category) => (
                    <MenuItem key={category._id} value={category.name}>
                      <Checkbox checked={categoriesValue.indexOf(category.name) > -1} />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!isValidInputs.categories && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.categoriesError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-checkbox-label">Directors</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={directorsValue}
                  onChange={(e) => handleChangeSelect(e, setDirectorsValue)}
                  input={<OutlinedInput label="Directors" />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {directorsData.map((director) => (
                    <MenuItem
                      key={director._id}
                      value={`${director.firstname} ${director.lastname}`}
                    >
                      <Checkbox
                        checked={
                          directorsValue.indexOf(
                            capitalCase(`${director.firstname} ${director.lastname}`)
                          ) > -1
                        }
                      />
                      <ListItemText
                        primary={capitalCase(`${director.firstname} ${director.lastname}`)}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!isValidInputs.directors && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.directorsError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                select
                label="Status"
                autoComplete="isActive"
                error={Boolean(touched.isActive && errors.isActive)}
                helperText={touched.isActive && errors.isActive}
                {...getFieldProps('isActive')}
              >
                {ISACTIVE_OPTIONS.map((isActive) => (
                  <MenuItem key={isActive.value} value={isActive.value}>
                    {isActive.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save Project
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
