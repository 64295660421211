import { Card, Modal, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import PropTypes from 'prop-types';

import UpdateAboutPageForm from './UpdateAboutPageForm';

const StyledModal = styled(Modal)({
  position: 'fixed',
  zIndex: 1300,
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Backdrop = styled('div')({
  zIndex: '-1px',
  position: 'fixed',
  right: 0,
  bottom: 0,
  top: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)'
});

const ModalCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 700,
  margin: '0 1rem'
}));

UpdateAboutPageModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  aboutPage: PropTypes.object,
  fetchAboutPage: PropTypes.func.isRequired
};

export default function UpdateAboutPageModal({ open, setOpen, aboutPage, fetchAboutPage }) {
  return (
    <StyledModal open={open} onClose={() => setOpen(false)} BackdropComponent={Backdrop}>
      <ModalCard>
        <Typography variant="h6">Edit About Page</Typography>
        <Typography variant="body2">Update the details below.</Typography>
        <UpdateAboutPageForm
          setOpen={setOpen}
          aboutPage={aboutPage}
          fetchAboutPage={fetchAboutPage}
        />
      </ModalCard>
    </StyledModal>
  );
}
