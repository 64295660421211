// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Home Page',
    path: '/cms/home-page',
    icon: getIcon('eva:home-outline'),
    private: false
  },
  {
    title: 'About Page',
    path: '/cms/about-page',
    icon: getIcon('eva:info-outline'),
    private: false
  },
  {
    title: 'Contact Page',
    path: '/cms/contact-page',
    icon: getIcon('eva:phone-outline'),
    private: false
  },
  {
    title: 'Projects',
    path: '/cms/projects',
    icon: getIcon('eva:video-outline'),
    private: false
  },
  {
    title: 'Directors',
    path: '/cms/directors',
    icon: getIcon('eva:person-outline'),
    private: false
  },
  {
    title: 'Reels',
    path: '/cms/reels',
    icon: getIcon('eva:film-outline'),
    private: false
  },
  {
    title: 'Categories',
    path: '/cms/categories',
    icon: getIcon('eva:copy-outline'),
    private: false
  },
  {
    title: 'Archive',
    path: '/cms/archives',
    icon: getIcon('eva:archive-outline'),
    private: false
  },
  {
    title: 'Users',
    path: '/cms/users',
    icon: getIcon('eva:people-outline'),
    private: true
  }
];

export default sidebarConfig;
