import axiosClient from './axios-client';

const get = () => axiosClient.get('api/contact-page');

const update = (data) =>
  axiosClient.patch('api/contact-page', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
const publishContact = (data) => axiosClient.patch('api/contact-page/publish', data);
const discardDraft = (data) => axiosClient.delete(`api/contact-page/discard/${data.id}`);

const contactPageAPI = { get, update, publishContact, discardDraft };
export default contactPageAPI;
