import axios from 'axios';

const userData = JSON.parse(localStorage.getItem('userData'));

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000',
  headers: {
    'auth-token': userData?.token
  },
  withCredentials: true
});

export default axiosClient;
