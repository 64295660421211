import { useMemo } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
// context
import { AuthContext } from './shared/context/AuthContext';
import { useAuth } from './shared/hooks/AuthHook';

// ----------------------------------------------------------------------

export default function App() {
  const { token, isAdmin, login, logout, user } = useAuth();

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <AuthContext.Provider
        value={useMemo(
          () => ({
            isLoggedIn: !!token,
            user,
            token,
            isAdmin,
            login,
            logout
          }),
          [user, token, isAdmin, login, logout]
        )}
      >
        <Router />
      </AuthContext.Provider>
    </ThemeConfig>
  );
}
