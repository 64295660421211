import { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddUserForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function AddUserForm({ setOpen, fetchUsers }) {
  const [error, setError] = useState(false);

  const UserSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    isAdmin: Yup.boolean(),
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      isAdmin: false,
      username: '',
      password: ''
    },
    validationSchema: UserSchema,
    onSubmit: async (data) => {
      try {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/user`, data, {
          headers: {
            'Content-Type': 'application/json',
            'auth-token': storedData.token
          },
          withCredentials: true
        });

        if (res) {
          fetchUsers();
          setOpen(false);
        } else {
          setError(true);
        }
      } catch (err) {
        setError(true);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} id="add-user-form">
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="firstname"
                label="First name"
                error={Boolean(touched.firstname && errors.firstname)}
                helperText={touched.firstname && errors.firstname}
                {...getFieldProps('firstname')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="lastname"
                label="Last name"
                error={Boolean(touched.lastname && errors.lastname)}
                helperText={touched.lastname && errors.lastname}
                {...getFieldProps('lastname')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Admin"
                autoComplete="admin"
                error={Boolean(touched.isAdmin && errors.isAdmin)}
                helperText={touched.isAdmin && errors.isAdmin}
                {...getFieldProps('isAdmin')}
              >
                <MenuItem value="true">Yes</MenuItem>
                <MenuItem value="false">No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="username"
                label="Username"
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                {...getFieldProps('username')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                autoComplete="password"
                label="Password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                {...getFieldProps('password')}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save User
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
