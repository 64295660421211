import axiosClient from './axios-client';

const get = () => axiosClient.get('api/about-page');

const update = (data) =>
  axiosClient.patch('api/about-page', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
const publishAbout = (data) => axiosClient.patch('api/about-page/publish', data);
const discardDraft = (data) => axiosClient.delete(`api/about-page/discard/${data.id}`);

const aboutPageAPI = { get, update, publishAbout, discardDraft };
export default aboutPageAPI;
