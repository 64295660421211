import { useCallback, useState } from 'react';

import { useFormik } from 'formik';

import { Alert, Box, Stack, TextField, Typography } from '@mui/material';

import { LoadingButton } from '@mui/lab';

import PropTypes from 'prop-types';

import * as Yup from 'yup';

import ImageUploadInput from '../ImageUploadInput';
import RichTextEditor from '../RichTextEditor';
import aboutPageAPI from '../../api/about-page';

UpdateHomePageForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  aboutPage: PropTypes.object,
  fetchAboutPage: PropTypes.func
};

export default function UpdateHomePageForm({ setOpen, aboutPage, fetchAboutPage }) {
  const [isError, setIsError] = useState(false);

  const existingImages =
    aboutPage?.slideImages.map((slideImage) => ({
      id: slideImage,
      url: `https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/about-page/slide-images/${slideImage}`
    })) || [];

  const initialValues = {
    text: aboutPage?.text || '',
    vimeoURL: aboutPage?.vimeoURL || ''
  };

  const validationSchema = Yup.object().shape({
    vimeoURL: Yup.string().required('Vimeo URL is required')
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('text', values.text);
      formData.append('vimeoURL', values.vimeoURL);

      const { newImages } = values.imageData;
      const { deleteImages } = values.imageData;
      if (newImages && newImages.length) {
        newImages.forEach((newImage) => {
          formData.append('newImage', newImage.file);
        });
      }

      formData.append('deleteImages', JSON.stringify(deleteImages));
      try {
        await aboutPageAPI.update(formData);

        fetchAboutPage();
        setOpen(false);
      } catch (error) {
        setIsError(true);
      }
    }
  });

  const { errors, touched, handleChange, handleSubmit, isSubmitting, values } = formik;

  const handleOnChangeText = useCallback((text) => {
    formik.setFieldValue('text', text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeImageUploadInput = useCallback((data) => {
    formik.setFieldValue('imageData', data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={2}>
      {isError && (
        <Alert severity="error" sx={{ my: 2 }}>
          Something went wrong. Try again later.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack>
            <Typography variant="caption">Slide Images</Typography>
            <ImageUploadInput
              existingImages={existingImages}
              onChange={handleOnChangeImageUploadInput}
            />
          </Stack>
          <Stack>
            <Typography variant="caption">Text</Typography>
            <RichTextEditor value={values.text} onChange={handleOnChangeText} />
          </Stack>
          <TextField
            label="Vimeo URL"
            name="vimeoURL"
            type="url"
            value={values.vimeoURL}
            onChange={handleChange}
            error={touched.vimeoURL && errors.vimeoURL}
            helperText={touched.vimeoURL && errors.vimeoURL}
          />
        </Stack>
        <Box display="flex" justifyContent="end" mt={3}>
          <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
            Save
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
