import axiosClient from './axios-client';

const getBanner = () => axiosClient.get('api/home-page/banner');
const updateBanner = (data) =>
  axiosClient.patch('api/home-page/banner', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
const publishBanner = (data) => axiosClient.patch('api/home-page/publish/banner', data);
const discardDraft = (data) => axiosClient.delete(`api/home-page/discard/banner/${data.id}`);

const homePageAPI = { getBanner, updateBanner, publishBanner, discardDraft };
export default homePageAPI;
