import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

AddDirectorForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  fetchDirectors: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function AddDirectorForm({ setOpen, fetchDirectors }) {
  const [fileNames, setFileNames] = useState({
    avatar: '',
    banner: '',
    nameTagMobile: '',
    nameTagDesktop: ''
  });
  const [files, setFiles] = useState({
    avatar: '',
    banner: '',
    nameTagMobile: '',
    nameTagDesktop: ''
  });
  const [isValidInputs, setIsValidInputs] = useState({
    avatar: true,
    banner: true,
    nameTagMobile: true,
    nameTagDesktop: true
  });
  const [errorInputs, setErrorInputs] = useState({
    avatar: '',
    banner: '',
    nameTagMobile: '',
    nameTagDesktop: ''
  });
  const [error, setError] = useState(false);

  const avatarFileInput = useRef(null);
  const bannerFileInput = useRef(null);
  const nameTagMobileFileInput = useRef(null);
  const nameTagDesktopFileInput = useRef(null);

  const handleAvatarUploadFile = () => {
    avatarFileInput.current.click();
  };

  const handleBannerUploadFile = () => {
    bannerFileInput.current.click();
  };

  const handleNameTagMobileFileInputUploadFile = () => {
    nameTagMobileFileInput.current.click();
  };

  const handleNameTagDesktopFileInputUploadFile = () => {
    nameTagDesktopFileInput.current.click();
  };

  const ProjectSchema = Yup.object().shape({
    firstname: Yup.string().required('Firstname name is required'),
    lastname: Yup.string().required('Lastname is required'),
    about: Yup.string().required('About is required')
  });

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      about: ''
    },
    validationSchema: ProjectSchema,
    onSubmit: async (data) => {
      const newIsValidInputs = isValidInputs;
      const newErrorInputs = errorInputs;

      if (!files.avatar) {
        newIsValidInputs.avatar = false;
        newErrorInputs.avatar = 'Avatar is required';
      } else if (files.avatar.size > 25 * 1024 * 1024) {
        newIsValidInputs.avatar = false;
        newErrorInputs.avatar = 'File is too big, please upload not more than 10mb';
      } else {
        newIsValidInputs.banner = true;
      }

      if (!files.banner) {
        newIsValidInputs.banner = false;
        newErrorInputs.banner = 'Banner is required';
      } else if (files.banner.size > 25 * 1024 * 1024) {
        newIsValidInputs.banner = false;
        newErrorInputs.banner = 'File is too big, please upload not more than 10mb';
      } else {
        newIsValidInputs.banner = true;
      }

      if (!files.nameTagMobile) {
        newIsValidInputs.nameTagMobile = false;
        newErrorInputs.nameTagMobile = 'Name tag mobile is required';
      } else if (files.nameTagMobile.size > 25 * 1024 * 1024) {
        newIsValidInputs.nameTagMobile = false;
        newErrorInputs.nameTagMobile = 'File is too big, please upload not more than 10mb';
      } else {
        newIsValidInputs.nameTagMobile = true;
      }

      if (!files.nameTagDesktop) {
        newIsValidInputs.nameTagDesktop = false;
        newErrorInputs.nameTagDesktop = 'Name tag desktop is required';
      } else if (files.nameTagDesktop.size > 25 * 1024 * 1024) {
        newIsValidInputs.nameTagDesktop = false;
        newErrorInputs.nameTagDesktop = 'File is too big, please upload not more than 10mb';
      } else {
        newIsValidInputs.nameTagDesktop = true;
      }

      setIsValidInputs(newIsValidInputs);
      setErrorInputs(newErrorInputs);

      if (
        files.avatar &&
        newIsValidInputs.avatar &&
        files.banner &&
        newIsValidInputs.banner &&
        files.nameTagMobile &&
        newIsValidInputs.nameTagMobile &&
        files.nameTagDesktop &&
        newIsValidInputs.nameTagDesktop
      ) {
        try {
          const formData = new FormData();
          const details = JSON.stringify(data);

          formData.append('avatar', files.avatar);
          formData.append('banner', files.banner);
          formData.append('nameTagMobile', files.nameTagMobile);
          formData.append('nameTagDesktop', files.nameTagDesktop);
          formData.append('details', details);

          const storedData = JSON.parse(localStorage.getItem('userData'));
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/director`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'auth-token': storedData.token
            },
            withCredentials: true
          });

          if (res) {
            fetchDirectors();
            setOpen(false);
          } else {
            setError(true);
          }
        } catch (err) {
          setError(true);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const fileUpload = (evt, type) => {
    if (evt.target.files && evt.target.files[0]) {
      const xFile = evt.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const filesize = e.total;
        const limitsize = 25 * 1024 * 1024;

        if (filesize > limitsize || !filesize) {
          if (type === 'avatar') {
            setIsValidInputs({
              ...isValidInputs,
              avatar: false
            });
            setErrorInputs({
              ...errorInputs,
              avatar: 'File is too big, please upload not more than 10mb'
            });
          } else if (type === 'banner') {
            setIsValidInputs({
              ...isValidInputs,
              banner: false
            });
            setErrorInputs({
              ...errorInputs,
              banner: 'File is too big, please upload not more than 10mb'
            });
          } else if (type === 'nameTagMobile') {
            setIsValidInputs({
              ...isValidInputs,
              nameTagMobile: false
            });
            setErrorInputs({
              ...errorInputs,
              nameTagMobile: 'File is too big, please upload not more than 10mb'
            });
          } else if (type === 'nameTagDesktop') {
            setIsValidInputs({
              ...isValidInputs,
              nameTagDesktop: false
            });
            setErrorInputs({
              ...errorInputs,
              nameTagDesktop: 'File is too big, please upload not more than 10mb'
            });
          }
        } else if (filesize) {
          if (type === 'avatar') {
            setIsValidInputs({
              ...isValidInputs,
              avatar: true
            });
          } else if (type === 'banner') {
            setIsValidInputs({
              ...isValidInputs,
              banner: true
            });
          } else if (type === 'nameTagMobile') {
            setIsValidInputs({
              ...isValidInputs,
              nameTagMobile: true
            });
          } else if (type === 'nameTagDesktop') {
            setIsValidInputs({
              ...isValidInputs,
              nameTagDesktop: true
            });
          }
        }

        if (type === 'avatar') {
          setFiles({
            ...files,
            avatar: xFile
          });
          setFileNames({
            ...fileNames,
            avatar: xFile.name
          });
        } else if (type === 'banner') {
          setFiles({
            ...files,
            banner: xFile
          });
          setFileNames({
            ...fileNames,
            banner: xFile.name
          });
        } else if (type === 'nameTagMobile') {
          setFiles({
            ...files,
            nameTagMobile: xFile
          });
          setFileNames({
            ...fileNames,
            nameTagMobile: xFile.name
          });
        } else if (type === 'nameTagDesktop') {
          setFiles({
            ...files,
            nameTagDesktop: xFile
          });
          setFileNames({
            ...fileNames,
            nameTagDesktop: xFile.name
          });
        }
      };

      reader.readAsDataURL(xFile);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="firstname"
                label="Firstname"
                error={Boolean(touched.firstname && errors.firstname)}
                helperText={touched.firstname && errors.firstname}
                {...getFieldProps('firstname')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="lastname"
                label="Lastname"
                error={Boolean(touched.lastname && errors.lastname)}
                helperText={touched.lastname && errors.lastname}
                {...getFieldProps('lastname')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="avatar"
                onClick={handleAvatarUploadFile}
                label="Avatar"
                value={fileNames.avatar}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={avatarFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e, 'avatar')}
              />
              {!isValidInputs.avatar && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.avatar}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="banner"
                onClick={handleBannerUploadFile}
                label="Banner"
                value={fileNames.banner}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={bannerFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e, 'banner')}
              />
              {!isValidInputs.banner && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.banner}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="nameTagMobile"
                onClick={handleNameTagMobileFileInputUploadFile}
                label="Name Tag Mobile"
                value={fileNames.nameTagMobile}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={nameTagMobileFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e, 'nameTagMobile')}
              />
              {!isValidInputs.nameTagMobile && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.nameTagMobile}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                autoComplete="nameTagDesktop"
                onClick={handleNameTagDesktopFileInputUploadFile}
                label="Name Tag Desktop"
                value={fileNames.nameTagDesktop}
                InputProps={{
                  readOnly: true
                }}
              />
              <input
                ref={nameTagDesktopFileInput}
                hidden
                type="file"
                accept="image/*"
                onChange={(e) => fileUpload(e, 'nameTagDesktop')}
              />
              {!isValidInputs.nameTagDesktop && (
                <Typography sx={{ fontSize: '12px', color: '#ff4842' }} ml={2} mt={0.4}>
                  {errorInputs.nameTagDesktop}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                multiline
                minRows={2}
                autoComplete="about"
                label="About"
                error={Boolean(touched.about && errors.about)}
                helperText={touched.about && errors.about}
                {...getFieldProps('about')}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save Project
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
