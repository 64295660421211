import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import { capitalCase } from 'text-case';

// ----------------------------------------------------------------------

AddArchiveForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  fetchArchives: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function AddArchiveForm({ setOpen, fetchArchives }) {
  const [directorsData, setDirectorsData] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchDirectors = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/director`);

        setDirectorsData(res.data.data);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    };

    fetchDirectors();
  }, []);

  const ArchiveSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    url: Yup.string().required('URL is required'),
    director: Yup.string().required('Director is required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      url: '',
      director: ''
    },
    validationSchema: ArchiveSchema,
    onSubmit: async (data) => {
      try {
        const storedData = JSON.parse(localStorage.getItem('userData'));
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/archive`, data, {
          headers: {
            'Content-Type': 'application/json',
            'auth-token': storedData.token
          },
          withCredentials: true
        });

        if (res) {
          fetchArchives();
          setOpen(false);
        } else {
          setError(true);
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="title"
                label="Title"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                {...getFieldProps('title')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                autoComplete="url"
                label="URL"
                error={Boolean(touched.url && errors.url)}
                helperText={touched.url && errors.url}
                {...getFieldProps('url')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                select
                label="Director"
                autoComplete="director"
                error={Boolean(touched.director && errors.director)}
                helperText={touched.director && errors.director}
                {...getFieldProps('director')}
              >
                {directorsData.map((director) => (
                  <MenuItem key={director._id} value={director._id}>
                    {capitalCase(`${director.firstname} ${director.lastname}`)}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save Archive
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              There is something wrong, please try again.
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
