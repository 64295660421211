import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, IconButton, styled } from '@mui/material';
import { grey } from '@mui/material/colors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';

ImageItem.propTypes = {
  src: PropTypes.string.isRequired,
  id: PropTypes.string,
  index: PropTypes.number,
  onDelete: PropTypes.func.isRequired
};

function ImageItem({ src, id, index, onDelete }) {
  const handleClick = () => {
    onDelete(index, id);
  };
  return (
    <Box position="relative">
      <Box
        component="img"
        src={src}
        height={70}
        width={140}
        borderRadius="4px"
        bgcolor={grey[200]}
        sx={{ objectFit: 'contain' }}
      />
      <IconButton
        aria-label="delete"
        onClick={handleClick}
        size="small"
        sx={{ position: 'absolute', top: -10, right: -10 }}
      >
        <HighlightOffIcon fontSize="inherit" />
      </IconButton>
    </Box>
  );
}

function ImageAddButton(props) {
  const StyledButton = styled(Button)({
    height: 70,
    width: 140,
    color: grey[500],
    borderRadius: '4px',
    backgroundColor: grey[200]
  });

  return (
    <StyledButton {...props}>
      <AddIcon />
    </StyledButton>
  );
}

ImageUploadInput.propTypes = {
  existingImages: PropTypes.arrayOf(
    PropTypes.exact({
      url: PropTypes.string.isRequired,
      id: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired
};

export default function ImageUploadInput({ existingImages, onChange }) {
  const inputEl = useRef(null);
  const [_existingImages, _setExistingImages] = useState(existingImages);
  const [newImages, setNewImages] = useState([]);
  const [deleteImages, setDeleteImages] = useState([]);

  const [data, setData] = useState({
    newImages: [],
    deleteImages: []
  });

  const handleFileChange = (e) => {
    const files = [...e.target.files];
    const images = [];
    files.forEach((file) => {
      const imageUrl = URL.createObjectURL(file);
      images.push({
        file,
        url: imageUrl
      });
    });

    setNewImages((prevState) => [...prevState, ...images]);
  };
  const handleOnDelete = (index, id) => {
    if (id) {
      const existingImagesFiltered = _existingImages.filter((_, i) => index !== i);
      _setExistingImages(existingImagesFiltered);

      setDeleteImages((prevState) => [...prevState, id]);
    } else {
      const newImagesFiltered = newImages.filter((_, i) => index !== i);
      setNewImages(newImagesFiltered);
    }
  };

  useEffect(() => {
    setData({
      newImages,
      deleteImages
    });
  }, [newImages, deleteImages]);

  useEffect(() => {
    onChange(data);
  }, [data, onChange]);
  return (
    // <Stack direction="row" spacing={2}>
    <Grid container spacing={2}>
      {_existingImages.map((existingImage, index) => (
        <Grid item key={index}>
          <ImageItem
            src={existingImage.url}
            id={existingImage.id}
            index={index}
            onDelete={handleOnDelete}
          />
        </Grid>
      ))}
      {newImages.map((newImage, index) => (
        <Grid item key={index}>
          <ImageItem key={index} src={newImage.url} index={index} onDelete={handleOnDelete} />
        </Grid>
      ))}
      <Grid item>
        <ImageAddButton onClick={() => inputEl.current.click()} />
      </Grid>
      <input
        ref={inputEl}
        onChange={handleFileChange}
        type="file"
        accept="images/*"
        hidden
        multiple
      />
    </Grid>
  );
}
