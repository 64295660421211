import { Alert, Box, FormHelperText, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

import { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import homePageAPI from '../../api/home-page';

const ImagePreview = styled(Box)({
  marginTop: 4,
  height: '200px',
  width: '100%',
  objectFit: 'contain'
});

UpdateHomePageForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  fetchBanner: PropTypes.func.isRequired
};

export default function UpdateHomePageForm({ setOpen, fetchBanner }) {
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isValidationError, setIsValidationError] = useState(false);
  const fileInputEl = useRef(null);

  const [banner, setBanner] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Check file size
    const fileLimit = 30000000; // 10mb
    if (file.size > fileLimit) {
      setIsValidationError(true);
    } else {
      setIsValidationError(false);
      const imageUrl = URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl);
      setBanner(file.name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    setIsError(false);

    const banner = e.target.banner.files[0];

    const formData = new FormData();

    formData.append('banner', banner);

    try {
      await homePageAPI.updateBanner(formData);
      setIsSubmitting(false);
      fetchBanner();
      setOpen(false);
    } catch (error) {
      setIsSubmitting(false);
      setIsError(true);
    }
  };

  return (
    <Box mt={2}>
      {isError && (
        <Alert severity="error" sx={{ my: 2 }}>
          Something went wrong. Try again later.
        </Alert>
      )}

      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          variant="outlined"
          onClick={() => fileInputEl.current.click()}
          label="Banner"
          InputProps={{
            readOnly: true
          }}
          value={banner}
        />

        <input
          ref={fileInputEl}
          type="file"
          name="banner"
          accept="image/*"
          onChange={handleFileChange}
          hidden
        />

        {isValidationError && (
          <FormHelperText error={isValidationError}>
            File is too big, please upload not more than 30mb
          </FormHelperText>
        )}

        {previewImageUrl && (
          <>
            <ImagePreview component="img" src={previewImageUrl} />

            <Box display="flex" justifyContent="end" mt={4}>
              <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
                Save
              </LoadingButton>
            </Box>
          </>
        )}
      </form>
    </Box>
  );
}
