import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import axios from 'axios';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  CardMedia
} from '@mui/material';
// utils
import { capitalCase } from 'text-case';
// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Label from '../components/Label';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import {
  AddReelModal,
  EditReelModal,
  ShowThumbnailModal,
  ShowVimeoModal
} from '../components/reels';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'thumbnail', label: 'Thumbnail', alignRight: false },
  { id: 'title', label: 'Title', alignRight: false },
  { id: 'subtitle', label: 'Subtitle', alignRight: false },
  { id: 'mainReel', label: 'Main Reel', alignRight: false },
  { id: 'isActive', label: 'Active', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Reels() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('title');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [reelsData, setReelsData] = useState([]);
  const [addReelModalOpen, setAddReelModalOpen] = useState(false);
  const [selectedThumbnail, setSelectedThumbnail] = useState('');
  const [showThumbnailModalOpen, setShowThumbnailModalOpen] = useState(false);
  const [selectedVimeo, setSelectedVimeo] = useState('');
  const [showVimeoModalOpen, setShowVimeoModalOpen] = useState(false);
  const [selectedReel, setSelectedReel] = useState({});
  const [showEditModalOpen, setShowEditModalOpen] = useState(false);

  useEffect(() => {
    fetchReels();
  }, []);

  const fetchReels = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/reel`);

      setReelsData(res.data.data);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = reelsData.map((n) => n.title);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reelsData.length) : 0;

  const filteredUsers = applySortFilter(reelsData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const handleShowThumbnailModal = (thumbnail) => {
    setSelectedThumbnail(thumbnail);
    setShowThumbnailModalOpen(true);
  };

  const handleShowVimeoModal = (vimeoUrl) => {
    setSelectedVimeo(vimeoUrl);
    setShowVimeoModalOpen(true);
  };

  const handleShowEditModal = (reel) => {
    setSelectedReel(reel);
    setShowEditModalOpen(true);
  };

  const handleDelete = async (reel) => {
    try {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/reel/${reel._id}`, {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': storedData.token
        },
        withCredentials: true
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
    fetchReels();
  };

  const handlePublish = async (reel) => {
    try {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/reel/${reel._id}`, reel, {
        headers: {
          'Content-Type': 'application/json',
          'auth-token': storedData.token
        },
        withCredentials: true
      });
      // eslint-disable-next-line no-empty
    } catch (err) {}
    fetchReels();
  };

  return (
    <Page title="Fragment CMS | Reels">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Reels
          </Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => setAddReelModalOpen(true)}
          >
            Add Reels
          </Button>
        </Stack>
        <Card>
          <Stack direction="row" alignItems="center" justifyContent="space-between" pr={2}>
            <Box onClick={() => setPage(0)}>
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
                searchLabel="Search reels..."
              />
            </Box>
            {/* <Box component="div" display="inline">
              <IconButton>
                <ArchiveIcon sx={{ color: '#262324' }} />
              </IconButton>
            </Box> */}
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={reelsData.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const {
                        _id,
                        title,
                        subtitle,
                        thumbnail,
                        mainReel,
                        vimeoUrl,
                        isActive,
                        status
                      } = row;
                      const isItemSelected = selected.indexOf(title) !== -1;

                      return (
                        <TableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell align="left">
                            <CardMedia
                              component="img"
                              height={50}
                              alt="thumbnail"
                              sx={{ cursor: 'pointer', maxWidth: '130px', minWidth: '130px' }}
                              image={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/reels/thumbnail/${thumbnail}`}
                              onClick={() => handleShowThumbnailModal(thumbnail)}
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                              }}
                            >
                              <Typography
                                variant="subtitle2"
                                onClick={() => handleShowVimeoModal(vimeoUrl)}
                                noWrap
                              >
                                {capitalCase(title)}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{subtitle}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={mainReel ? 'success' : 'error'}>
                              {mainReel ? 'Yes' : 'No'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={isActive ? 'success' : 'error'}>
                              {isActive ? 'Yes' : 'No'}
                            </Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="ghost"
                              color={status === 'published' ? 'success' : 'default'}
                            >
                              {capitalCase(status)}
                            </Label>
                          </TableCell>
                          <TableCell align="right">
                            <UserMoreMenu
                              actions={[
                                {
                                  id: 'edit',
                                  label: 'Edit',
                                  icon: 'eva:edit-2-outline',
                                  onClick: () => handleShowEditModal(row)
                                },
                                {
                                  id: 'delete',
                                  label: 'Delete',
                                  icon: 'eva:trash-outline',
                                  onClick: () => handleDelete(row)
                                },
                                row.status === 'draft' && {
                                  id: 'publish',
                                  label: 'Publish',
                                  icon: 'eva:cloud-upload-outline',
                                  onClick: () => handlePublish(row)
                                }
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={reelsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <AddReelModal open={addReelModalOpen} setOpen={setAddReelModalOpen} fetchReels={fetchReels} />
      <ShowThumbnailModal
        open={showThumbnailModalOpen}
        setOpen={setShowThumbnailModalOpen}
        selectedThumbnail={selectedThumbnail}
      />
      <ShowVimeoModal
        open={showVimeoModalOpen}
        setOpen={setShowVimeoModalOpen}
        selectedVimeo={selectedVimeo}
      />
      <EditReelModal
        open={showEditModalOpen}
        setOpen={setShowEditModalOpen}
        selectedReel={selectedReel}
        fetchReels={fetchReels}
      />
    </Page>
  );
}
