import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
// formik
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Box, Grid, TextField, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// context
import { AuthContext } from '../../../shared/context/AuthContext';

// ----------------------------------------------------------------------

ChangePassForm.propTypes = {
  setOpen: PropTypes.func.isRequired,
  selectedUser: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired
};

// ----------------------------------------------------------------------

export default function ChangePassForm({ setOpen, selectedUser, fetchUsers }) {
  const auth = useContext(AuthContext);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const UsersSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    newPassword: Yup.string().required('New password is required')
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: ''
    },
    validationSchema: UsersSchema,
    onSubmit: async (data) => {
      const storedData = JSON.parse(localStorage.getItem('userData'));
      data.id = selectedUser._id;
      data.isAdmin = auth.isAdmin;

      try {
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/api/user/password`, data, {
          headers: {
            'Content-Type': 'application/json',
            'auth-token': storedData.token
          },
          withCredentials: true
        });

        if (res) {
          fetchUsers();
          setOpen(false);
        } else {
          setError(true);
          setErrorMessage('Something went wrong, please try again later.');
        }
      } catch (err) {
        setError(true);
        setErrorMessage(err.response.data.message);
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        id="add-project-form"
        encType="multipart/form-data"
      >
        <Box mt={3}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                autoComplete="oldPassword"
                label="Old password"
                error={Boolean(touched.oldPassword && errors.oldPassword)}
                helperText={touched.oldPassword && errors.oldPassword}
                {...getFieldProps('oldPassword')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                type="password"
                autoComplete="newPassword"
                label="New password"
                error={Boolean(touched.newPassword && errors.newPassword)}
                helperText={touched.newPassword && errors.newPassword}
                {...getFieldProps('newPassword')}
              />
            </Grid>
          </Grid>
          <Stack direction="row" justifyContent="end" mt={2} spacing={1}>
            <LoadingButton size="medium" variant="contained" type="submit" loading={isSubmitting}>
              Save User
            </LoadingButton>
          </Stack>
          {error && (
            <Typography
              align="right"
              sx={{ color: (theme) => theme.palette.error.main, marginTop: 2 }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Form>
    </FormikProvider>
  );
}
