import { useEffect, useState } from 'react';

import { Box, Button, Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';

import homePageAPI from '../api/home-page';

import UpdateHomePageModal from '../components/homepage/UpdateHomePageModal';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

export default function HomePage() {
  const [updateHomePageModalOpen, setUpdateHomePageModalOpen] = useState(false);
  const [isDiscarding, setIsDiscarding] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [banner, setBanner] = useState(null);
  const [draft, setDraft] = useState(null);

  const fetchBanner = async (action, id) => {
    // eslint-disable-next-line default-case
    switch (action) {
      case 'discard':
        await homePageAPI.discardDraft({ id });
        break;

      case 'publish':
        await homePageAPI.publishBanner({ id });
        break;
    }

    setIsPublishing(false);
    setIsDiscarding(false);

    const { data } = await homePageAPI.getBanner();
    const { published, draft, isPreview } = data;

    setDraft(draft);
    setIsDraft(isPreview);
    setBanner(isPreview ? draft.banner : published.banner);
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const handlePublish = (id) => {
    setIsPublishing(true);
    fetchBanner('publish', id);
  };

  const handleDiscard = (id) => {
    setIsDiscarding(true);
    fetchBanner('discard', id);
  };

  return (
    <Page title="Fragment CMS | Home Page">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Home Page
          </Typography>
          <Stack direction="row" spacing={1}>
            {isDraft && (
              <>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  loading={isPublishing}
                  startIcon={<Iconify icon="eva:cloud-upload-outline" />}
                  onClick={() => handlePublish(draft._id)}
                >
                  Publish
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  color="error"
                  loading={isDiscarding}
                  startIcon={<Iconify icon="eva:trash-outline" />}
                  onClick={() => handleDiscard(draft._id)}
                >
                  Discard
                </LoadingButton>
              </>
            )}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:edit-outline" />}
              onClick={() => setUpdateHomePageModalOpen(true)}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
        <Card>
          <CardContent>
            <Stack>
              <Typography variant="subtitle2" mb={2} gutterBottom>
                Banner
              </Typography>

              {banner && (
                <Box
                  component="img"
                  height={200}
                  width={400}
                  borderRadius="4px"
                  bgcolor={grey[200]}
                  sx={{ objectFit: 'contain' }}
                  src={`https://umbra-digital.sgp1.digitaloceanspaces.com/frgmntmnl/home-page/banner/${banner}`}
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
      <UpdateHomePageModal
        open={updateHomePageModalOpen}
        setOpen={setUpdateHomePageModalOpen}
        fetchBanner={fetchBanner}
      />
    </Page>
  );
}
